import iReducer from '../../../../Global/State/reducerInterface';

export default function Statistics({ state, dispatch }: iReducer) {
  return (
    <div>
      <br />
      <div>Total Money Earned: ${state.totalMoneyEarned.toLocaleString()}</div>
      <div>Total Items Sold: {state.totalItemsSold}</div>
      <div>
        Total Transportation Costs Paid: $
        {state.totalTransportationCostsPaid.toLocaleString()}
      </div>
    </div>
  );
}
