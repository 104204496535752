import iReducer from '../../../Global/State/reducerInterface';
import Achievements from '../ContentTabs/Achievements';
import Statistics from '../ContentTabs/Statistics';
import './style.css';
import Compendium from '../ContentTabs/Compendium';
import TransactionHistory from '../ContentTabs/TransactionHistory';
import Settings from '../ContentTabs/Settings';

interface iFullScreenContent extends iReducer {
  activeTab: number;
}

export default function FullScreenContent({
  state,
  dispatch,
  activeTab,
}: iFullScreenContent) {
  const renderActiveManagementTab = () => {
    switch (activeTab) {
      case 0:
        return <Statistics state={state} dispatch={dispatch} />;
      case 1:
        return <Achievements />;
      case 2:
        return <Compendium state={state} dispatch={dispatch} />;
      case 3:
        return <TransactionHistory state={state} dispatch={dispatch} />;
      case 4:
        return <div>Marketplace</div>;
      case 5:
        return <div>Theyna</div>;
      case 6:
        return <Settings state={state} dispatch={dispatch} />;
      default:
        return <div>Content One</div>;
    }
  };

  return (
    <div className="fullScreenContentContainer">
      <div className="fullScreenContent">{renderActiveManagementTab()}</div>
    </div>
  );
}
