import iReducer from '../../../Global/State/reducerInterface';
import FacilitiesItem from '../Facilitiesitem';
import { range } from 'lodash';
import './style.css';
import { useState, useEffect } from 'react';

export default function Facilities({ state, dispatch }: iReducer) {
  const [warehouses, setWarehouses] = useState(state.warehouses);
  const [searchQuery, setSearchQuery] = useState('');

  const currentQuery = (query: string) => {
    setSearchQuery(query);
  };

  useEffect(() => {
    let _warehouses = state.warehouses;

    if (searchQuery) {
      _warehouses = _warehouses.filter((_warehouse) =>
        _warehouse
          .getNickName()
          .toLowerCase()
          .includes(searchQuery.toLowerCase())
      );
    }

    setWarehouses(_warehouses);
  }, [searchQuery, state]);

  return (
    <div className="facilitiesTable">
      <div className="inventoryHeader">
        <div>Storage</div>
        <input></input>
      </div>

      <b>Storage:</b>
      <div>Available Probes</div>
      {range(0, Object.keys(state.storage.probes).length).map((i) => (
        <div key={i}>
          <div>Probe: {state.storage.probes[i].getAmount()}</div>
          <div>
            - Target Item: {state.storage.probes[i].getTargetItem().displayName}
          </div>
          <div>
            - Target Location: {state.storage.probes[i].getTargetLocation()}
          </div>
          <div>- Size: {state.storage.probes[i].getUpgrades().getSize()}</div>
          <div>
            - Storage: {state.storage.probes[i].getUpgrades().getStorage()}
          </div>
          <div>
            - Shielding: {state.storage.probes[i].getUpgrades().getShielding()}
          </div>
          <div>
            - ExteriorColor:{' '}
            {state.storage.probes[i].getUpgrades().getExteriorColor()}
          </div>
        </div>
      ))}

      <div className="warehouseHeader">
        <div>Warehouses</div>
        <span>Search</span>
        <input className="" onChange={(e) => currentQuery(e.target.value)} />
      </div>

      {range(0, Object.keys(warehouses).length).map((i) => (
        <FacilitiesItem
          state={state}
          dispatch={dispatch}
          warehouse={warehouses[i]}
        />
      ))}
    </div>
  );
}
