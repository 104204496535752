import iReducer from '../../../../Global/State/reducerInterface';

export default function Settings({ state, dispatch }: iReducer) {
  return (
    <div>
      <br />
      <div className="importContainer">
        <div className="importInnerContent">
          <div className="importHeader">
            <div>
              <b>Manage Saves</b>
            </div>
          </div>
          <div className="manageAction">
            <button className="manageButton">Import Existing Save</button>
          </div>
          <div className="manageAction">
            <button className="manageButton">Export Current Save</button>
          </div>
        </div>
      </div>
    </div>
  );
}
