import { useState } from 'react';
import './style.css';
import iReducer from '../../Global/State/reducerInterface';
import Nav from '../../components/NavComponents/Nav';
import Inventory from '../../components/LocalComponents/Inventory';
import MainLoading from '../../components/MainLoading';
import Facilities from '../../components/LocalComponents/Facilities';
import FullScreenMenu from '../../components/FullScreenComponents/FullScreenMenu';
import FullScreenTabItem from '../../components/FullScreenComponents/FullScreenMenu/FullScreenTab/FullScreenTabItem';
import resetCookie from '../../Global/State/InitData/CookieConversion/resetCookie';

export default function Home({ state, dispatch }: iReducer) {
  const [managementOpen, setManagementOpen] = useState(false);
  const [playerDetailsOpen, setPlayerDetailsOpen] = useState(false);
  const [settings, setSettings] = useState(false);

  const toggleManagement = () => {
    setManagementOpen((managementOpen) => !managementOpen);
  };

  const toggleSettings = () => {
    setSettings((settings) => !settings);
  };

  const togglePlayerDetails = () => {
    setPlayerDetailsOpen((playerDetailsOpen) => !playerDetailsOpen);
  };

  return (
    <>
      <MainLoading />
      <FullScreenMenu
        state={state}
        dispatch={dispatch}
        backgroundColor="#000"
        menuOpen={managementOpen}
        toggleMenu={toggleManagement}
        tabs={[
          new FullScreenTabItem(4, 'Marketplace'),
          new FullScreenTabItem(5, 'Theyna Marketplace'),
        ]}
      />
      <FullScreenMenu
        state={state}
        dispatch={dispatch}
        backgroundColor="#1d1e38"
        menuOpen={playerDetailsOpen}
        toggleMenu={togglePlayerDetails}
        tabs={[
          new FullScreenTabItem(0, 'Statistics'),
          new FullScreenTabItem(1, 'Achievements'),
          new FullScreenTabItem(2, 'Compendium'),
          new FullScreenTabItem(3, 'Transaction History'),
        ]}
      />
      <FullScreenMenu
        state={state}
        dispatch={dispatch}
        backgroundColor="#808080"
        menuOpen={settings}
        toggleMenu={toggleSettings}
        tabs={[new FullScreenTabItem(6, 'Settings')]}
      />
      <Nav
        toggleNav={() => null}
        toggleManagement={toggleManagement}
        toggleSettings={toggleSettings}
        togglePlayerDetails={togglePlayerDetails}
      />
      <div
        className={
          managementOpen || playerDetailsOpen || settings
            ? 'mainContent'
            : 'mainContent showScroll'
        }
      >
        <div className="sideContainer"></div>
        <div>
          <div className="centerContainer">
            <div className="localSelection">
              <Inventory state={state} dispatch={dispatch} />
            </div>
            <div className="localSelection">
              <Facilities state={state} dispatch={dispatch} />
            </div>
          </div>
        </div>
        <div className="sideContainer">
          {/* Temp for testing values */}
          <div>Curr Money: {state.money}</div>
          <div>Total Money: {state.totalMoneyEarned}</div>
          <div>Total Items Sold: {state.totalItemsSold}</div>
          <div>
            Total Transport Fees Paid: {state.totalTransportationCostsPaid}
          </div>
          <button onClick={() => resetCookie()}>Reset Cookie</button>
        </div>
        {managementOpen || playerDetailsOpen || settings ? (
          <div className="spacer"></div>
        ) : null}
      </div>
    </>
  );
}
