import iReducer from '../../../../Global/State/reducerInterface';
import { range } from 'lodash';
import { allItems } from '../../../../Global/Attributes/Item/AllItems';

export default function Compendium({ state, dispatch }: iReducer) {
  return (
    <div>
      <h3>Compendium:</h3>
      {range(1, Object.keys(allItems).length).map((i) =>
        state.compendium.get(i) ? (
          <div key={i}>{allItems[i].displayName}</div>
        ) : (
          <div key={i}>Missing: {i}</div>
        )
      )}
    </div>
  );
}
