import Transaction from '../History/Transaction/Transaction';
import inventory from '../Inventory/inventory';

export function incrementAmount(
  playerInventory: inventory,
  itemID: number,
  newAmount: number | string | boolean | Transaction
) {
  console.log('itemID', itemID);
  let item = playerInventory.getInventoryItemByID(itemID);

  if (typeof newAmount === 'number' && item != null) {
    item.amount += Number(newAmount);
  }

  return playerInventory;
}

export function decrementAmount(
  playerInventory: inventory,
  itemID: number,
  newAmount: number | string | boolean | Transaction
) {
  let item = playerInventory.getInventoryItemByID(itemID);

  if (typeof newAmount === 'number' && item != null) {
    item.amount -= Number(newAmount);

    // Should never happened but additional check to prevent negative item amounts
    if (item.amount < 0) item.amount = 0;
  }

  return playerInventory;
}
