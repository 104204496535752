import iReducer from '../../../Global/State/reducerInterface';
import { range } from 'lodash';
import Warehouse from '../../../Global/Attributes/Facilities/Warehouse/Warehouse';

interface iFacilitiesItem extends iReducer {
  warehouse: Warehouse;
}

export default function FacilitiesItem({
  state,
  dispatch,
  warehouse,
}: iFacilitiesItem) {
  return (
    <>
      <hr />
      <b>
        {warehouse.getNickName()} - ({warehouse.getCapacity()})
      </b>
      <div>Available Probes</div>
      {range(0, Object.keys(warehouse.probes).length).map((k) => (
        <div key={k}>
          <div>Probe: {warehouse.probes[k].getAmount()}</div>
          <div>
            - Target Item: {warehouse.probes[k].getTargetItem().displayName}
          </div>
          <div>
            - Target Location: {warehouse.probes[k].getTargetLocation()}
          </div>
          <div>- Size: {warehouse.probes[k].getUpgrades().getSize()}</div>
          <div>- Storage: {warehouse.probes[k].getUpgrades().getStorage()}</div>
          <div>
            - Shielding: {warehouse.probes[k].getUpgrades().getShielding()}
          </div>
          <div>
            - ExteriorColor:{' '}
            {warehouse.probes[k].getUpgrades().getExteriorColor()}
          </div>
        </div>
      ))}
    </>
  );
}
