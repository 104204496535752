import './style.css';
import { motion } from 'framer-motion';

export default function MainLoading() {
  return (
    <motion.div
      className="mainLoading"
      initial={{ opacity: 1 }}
      animate={{
        opacity: 0,
        transitionEnd: {
          display: 'none',
        },
      }}
      transition={{
        duration: 0.3,
        delay: 1.0,
      }}
    >
      <div>Loading...</div>
    </motion.div>
  );
}
