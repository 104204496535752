export default class Marketplace {
  private id: number;
  private location: string;
  private feePercentage: number;
  private buyCost: number;
  private sellPrice: number;
  private transportationCost: number;

  constructor(
    _id: number,
    _location: string,
    _feePercentage: number,
    _buyCost: number,
    _sellPrice: number,
    _transporationCost: number
  ) {
    this.id = _id;
    this.location = _location;
    this.feePercentage = _feePercentage;
    this.buyCost = _buyCost;
    this.sellPrice = _sellPrice;
    this.transportationCost = _transporationCost;
  }

  // Getters
  getID(): number {
    return this.id;
  }

  getLocation(): string {
    return this.location;
  }

  getFeePercentage(): number {
    return this.feePercentage;
  }

  getBuyCost(): number {
    return this.buyCost;
  }

  getSellPrice(): number {
    return this.sellPrice;
  }

  getTransportCost(): number {
    return this.transportationCost;
  }

  // Setters
  setFeePercentage(newAmount: number): number {
    this.feePercentage = newAmount;
    return this.feePercentage;
  }

  setBuyCost(newAmount: number): number {
    this.buyCost = newAmount;
    return this.buyCost;
  }
}
