import Transaction from '../../../../Global/Attributes/History/Transaction/Transaction';

export default function loadTransactionHistory(
  _cookieTransactionHistory: any[],
  savedDataTransactionHistory: Transaction[]
) {
  for (let i = 0; i < _cookieTransactionHistory.length; i++) {
    const {
      TH_itemName,
      TH_isBuy,
      TH_buyPrice,
      TH_sellPrice,
      TH_transportationCost,
      TH_quantity,
      TH_feesPaid,
      TH_marketplaceName,
      TH_total,
    } = _cookieTransactionHistory[i];

    if (
      typeof TH_itemName != 'string' ||
      typeof TH_isBuy != 'boolean' ||
      isNaN(TH_buyPrice) ||
      isNaN(TH_sellPrice) ||
      isNaN(TH_transportationCost) ||
      isNaN(TH_quantity) ||
      isNaN(TH_feesPaid) ||
      typeof TH_marketplaceName != 'string' ||
      isNaN(TH_total)
    ) {
      console.log(
        'Invalid transaction history element: ',
        _cookieTransactionHistory[i]
      );
    } else {
      const newTransaction = new Transaction(
        TH_itemName,
        TH_isBuy,
        TH_buyPrice,
        TH_sellPrice,
        TH_transportationCost,
        TH_quantity,
        TH_feesPaid,
        TH_marketplaceName,
        TH_total
      );

      if (newTransaction instanceof Transaction) {
        savedDataTransactionHistory.unshift(newTransaction);
        console.log('ADD TRANSACTION');

        if (savedDataTransactionHistory.length > 150) {
          console.log('TRANSACTION HISTORY FULL');
          savedDataTransactionHistory.pop();
        }
      }
    }
  }
}
