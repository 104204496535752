import Item from "../Item/Item";

export default class inventoryItem {
  public item: Item;
  public amount: number;

  constructor(_item: Item, _amount: number) {
    this.item = _item;
    this.amount = _amount;
  }
}