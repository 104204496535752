import './style.css';

interface iFullScreenTab {
  activeTab: number;
  setActiveTab: CallableFunction;
  tabID: number;
  displayStr: string;
}

export default function FullScreenTab({
  activeTab,
  setActiveTab,
  tabID,
  displayStr,
}: iFullScreenTab) {
  return (
    <div
      className={activeTab === tabID ? 'fullScreenTab-active' : 'fullScreenTab'}
      onClick={() => setActiveTab(tabID)}
    >
      {displayStr}
    </div>
  );
}
