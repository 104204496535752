import Transaction from '../History/Transaction/Transaction';

export function updateMoney(
  currentState: number,
  amount: number | string | boolean | Transaction
) {
  if (typeof amount === 'number') {
    return Number((currentState + amount).toFixed(2));
  }

  return currentState;
}
