import Warehouse from '../../../Global/Attributes/Facilities/Warehouse/Warehouse';
import inventory from '../../../Global/Attributes/Inventory/inventory';
import State from '../State';
import Cookies from 'universal-cookie';
import convertProbesForWarehouse from './CookieConversion/convertProbesForWarehouse';
import { currentVersion } from './currentVersion';
import loadCompendium from './CookieConversion/loadCompenidum';
import loadInventory from './CookieConversion/loadInventory';
import loadTransactionHistory from './CookieConversion/loadTransactionHistory';

export default function loadGlobalData() {
  const cookies = new Cookies();
  let defaultGlobalSettings: State = {
    version: currentVersion,
    money: 0,
    pets: [],
    compendium: new Map<number, boolean>(),
    inventory: new inventory([]),
    transactionHistory: [],
    totalMoneyEarned: 0,
    totalItemsSold: 0,
    totalTransportationCostsPaid: 0,
    storage: new Warehouse(true, 0, 'Storage', []),
    warehouses: [],
  };

  // if cookies are found use data saved in local cookie
  if (cookies.get('phantom')) {
    defaultGlobalSettings = parseFoundCookie();
    console.log('PARSED STATE: ', defaultGlobalSettings);
  }

  return defaultGlobalSettings;
}

const parseFoundCookie = () => {
  const cookies = new Cookies();
  const cookieData = cookies.get('phantom');

  let savedData: State = {
    version: -1,
    money: 0,
    pets: [],
    compendium: new Map<number, boolean>(),
    inventory: new inventory([]),
    transactionHistory: [],
    totalMoneyEarned: 0,
    totalItemsSold: 0,
    totalTransportationCostsPaid: 0,
    storage: new Warehouse(true, 0, 'Storage', []),
    warehouses: [],
  };

  // Money
  // If not number then fail loading
  if (Number(cookieData.money)) savedData.money = Number(cookieData.money);

  // Skip pets for now

  // Compendium
  if (cookieData.compendium) {
    // If not number[] then fail loading
    savedData.compendium = loadCompendium(cookieData.compendium);
  }

  // Inventory
  if (cookieData.inventory) {
    loadInventory(cookieData.inventory, savedData.inventory);
  }

  // Transaction History
  if (cookieData.transactionHistory) {
    loadTransactionHistory(
      cookieData.transactionHistory,
      savedData.transactionHistory
    );
  }

  // totalMoneyEarned
  // If not number then fail loading
  if (Number(cookieData.totalMoneyEarned)) {
    savedData.totalMoneyEarned = Number(cookieData.totalMoneyEarned);
  }

  // totalItemsSold
  // If not number then fail loading
  if (Number(cookieData.totalItemsSold)) {
    savedData.totalItemsSold = Number(cookieData.totalItemsSold);
  }

  // Transportation Fees
  // If not number then fail loading
  if (Number(cookieData.totalTransportationCostsPaid)) {
    savedData.totalTransportationCostsPaid = Number(
      cookieData.totalTransportationCostsPaid
    );
  }

  // Storage
  if (cookieData.storage) {
    savedData.storage.probes = convertProbesForWarehouse(cookieData.storage);
  }

  // Warehouses
  if (cookieData.warehouses) {
    for (let i = 0; i < cookieData.warehouses.length; i++) {
      const { W_isStorage, W_capacity, W_nickName, W_probes } =
        cookieData.warehouses[i];

      if (
        typeof W_isStorage != 'boolean' ||
        isNaN(W_capacity) ||
        typeof W_nickName != 'string'
      ) {
        console.log(
          'Invalid warehouse in storage (not probes): ',
          cookieData.warehouses[i]
        );
      } else {
        let newWarehouse = new Warehouse(
          W_isStorage,
          W_capacity,
          W_nickName,
          convertProbesForWarehouse(W_probes)
        );

        savedData.warehouses.push(newWarehouse);
      }
    }
  }

  return savedData;
};
