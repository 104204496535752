import Transaction from '../History/Transaction/Transaction';

export function updateTotalStatValue(
  currentTotal: number,
  amount: number | string | boolean | Transaction
) {
  if (typeof amount === 'number' && amount > 0) {
    return Number((currentTotal + amount).toFixed(2));
  }

  return currentTotal;
}
