import './style.css';
import { useState } from 'react';
import { motion } from 'framer-motion';
import FullScreenTab from './FullScreenTab';
import { X } from 'react-feather';
import OutsideClickHandler from 'react-outside-click-handler';
import iReducer from '../../../Global/State/reducerInterface';
import FullScreenTabItem from './FullScreenTab/FullScreenTabItem';
import { range } from 'lodash';
import FullScreenContent from '../FullScreenContent';

interface iFullScreenMenu extends iReducer {
  menuOpen: boolean;
  toggleMenu: Function;
  backgroundColor: string;
  tabs: FullScreenTabItem[];
}

export default function FullScreenMenu({
  state,
  dispatch,
  menuOpen,
  toggleMenu,
  backgroundColor,
  tabs,
}: iFullScreenMenu) {
  const [activeTab, setActiveTab] = useState(tabs[0].tabID);

  const dynamicStyles = {
    backgroundColor: backgroundColor,
  };

  return menuOpen ? (
    <OutsideClickHandler onOutsideClick={() => toggleMenu()}>
      <motion.div
        className="fullScreenMenu"
        style={dynamicStyles}
        initial={{ height: '0vh' }}
        animate={{
          height: '85vh',
          transitionEnd: {
            height: '85vh',
            visibility: 'visible',
          },
        }}
        transition={{
          duration: 0.3,
        }}
      >
        <motion.div
          initial={{ opacity: 0 }}
          animate={{
            opacity: 1,
          }}
          transition={{
            delay: 0.3,
            duration: 0.7,
          }}
        >
          <div className="fullScreenNav" style={dynamicStyles}>
            <X className="xIcon" size={26} onClick={() => toggleMenu()} />
          </div>
          <div className="fullScreenTabs">
            {range(0, Object.keys(tabs).length).map((i) => (
              <FullScreenTab
                key={i}
                setActiveTab={setActiveTab}
                tabID={tabs[i].tabID}
                activeTab={activeTab}
                displayStr={tabs[i].displayStr}
              />
            ))}
          </div>
          <FullScreenContent
            activeTab={activeTab}
            state={state}
            dispatch={dispatch}
          />
        </motion.div>
      </motion.div>
    </OutsideClickHandler>
  ) : (
    <motion.div
      className="fullScreenMenu"
      style={dynamicStyles}
      initial={{ height: '80%' }}
      animate={{
        height: '0%',
        transitionEnd: {
          height: '0%',
          visibility: 'hidden',
        },
      }}
      transition={{
        duration: 0.3,
      }}
    ></motion.div>
  );
}
