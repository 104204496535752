import Transaction from '../History/Transaction/Transaction';

export function updateCompendium(
  playerCompendium: Map<number, boolean>,
  itemID: string | number | boolean | Transaction
) {
  if (typeof itemID === 'number') {
    if (
      (!playerCompendium.size || !playerCompendium.has(itemID)) &&
      itemID > 0
    ) {
      playerCompendium.set(itemID, true);
    }
  }

  return playerCompendium;
}
