import Marketplace from './Marketplace/Marketplace';

export default class Item {
  id: number;
  displayName: string;
  sellCosts: Marketplace[];

  constructor(
    _id: number,
    _displayName: string,
    _sellCosts: Marketplace[]
  ) {
    this.id = _id;
    this.displayName = _displayName;
    this.sellCosts = _sellCosts;
  }
}
