import './style.css';
import { useState } from 'react';
import Transaction from '../../../Global/Attributes/History/Transaction/Transaction';
import inventoryItem from '../../../Global/Attributes/Inventory/inventoryItem';
import Marketplace from '../../../Global/Attributes/Item/Marketplace/Marketplace';
import iReducer from '../../../Global/State/reducerInterface';

interface iSellPanel extends iReducer {
  invItem: inventoryItem;
}

export default function SellPanel({ state, dispatch, invItem }: iSellPanel) {
  const [activeMarketPlace, setActiveMarketplace] = useState(0);
  const [sellQuantity, setSellQuantity] = useState<number | string>(0);
  const [sellError, setSellError] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  const handleMarketplaceChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (!Number.isNaN(e.target.value) && Number(e.target.value) >= 0) {
      setActiveMarketplace(Number(e.target.value));
    } else {
      setActiveMarketplace(0);
    }
  };

  const handleQuantityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length === 0) {
      setSellQuantity('');
    }

    if (Number(e.target.value)) {
      setSellQuantity(Number(e.target.value));
    }
  };

  let amount = invItem.amount;
  let price = invItem.item.sellCosts[activeMarketPlace].getSellPrice();

  let feePercentage = invItem.item.sellCosts[activeMarketPlace].getFeePercentage();

  let fees = Number((Number(sellQuantity) * feePercentage).toFixed(2));

  let transporationCosts =
    invItem.item.sellCosts[activeMarketPlace].getTransportCost();

  let total = Number(
    (Number(sellQuantity) * price - fees - transporationCosts).toFixed(2)
  );

  const submitSell = () => {
    if (invalidSale()) {
      setSellError(true);
    } else {
      processSale();
    }
  };

  const invalidSale = () => {
    if (typeof sellQuantity === 'string' || Number.isNaN(sellQuantity)) {
      setErrorMsg('Error: Invalid quantity input | input is NaN');
      return true;
    }

    if (sellQuantity <= 0) {
      setErrorMsg('Error: Sell quantity cannot be less than 1');
      return true;
    }

    if (sellQuantity > amount) {
      setErrorMsg(
        'Error: Sell quantity exceeds inventory amount | (' +
          sellQuantity +
          '>' +
          amount +
          ')'
      );
      return true;
    }

    setSellError(false);
    return false;
  };

  const processSale = () => {
    // Update money
    dispatch({
      attribute: 'money',
      operation: 'update',
      id: -1,
      payload: total,
    });

    // Update item amount in inventory
    dispatch({
      attribute: 'item',
      operation: 'decrementAmount',
      id: invItem.item.id,
      payload: sellQuantity,
    });

    // Update stats
    dispatch({
      attribute: 'stat',
      operation: 'updateTotalMoney',
      id: -1,
      payload: total,
    });

    dispatch({
      attribute: 'stat',
      operation: 'updateTotalItemsSold',
      id: -1,
      payload: sellQuantity,
    });

    dispatch({
      attribute: 'stat',
      operation: 'updateTotalTransportCostsPaid',
      id: -1,
      payload: transporationCosts,
    });

    // Update transaction history
    dispatch({
      attribute: 'history',
      operation: 'addTransaction',
      id: -1,
      payload: new Transaction(
        invItem.item.displayName,
        false,
        0,
        price,
        transporationCosts,
        sellQuantity,
        fees,
        invItem.item.sellCosts[activeMarketPlace].getLocation(),
        total
      ),
    });

    // Update compendium
    dispatch({
      attribute: 'compendium',
      operation: 'updateCompendium',
      id: -1,
      payload: invItem.item.id,
    });

    setSellQuantity(0);
  };

  return (
    <div className="sellPanel">
      <table>
        <tbody>
          <tr>
            <td>
              <span>Quantity</span>
            </td>
            <td>
              <div>
                <input
                  value={sellQuantity}
                  onChange={(e) => handleQuantityChange(e)}
                ></input>
              </div>
              <button onClick={() => setSellQuantity(1)}>Sell 1</button>
              <button onClick={() => setSellQuantity(Math.round(amount / 2))}>
                Sell Half
              </button>
              <button onClick={() => setSellQuantity(amount)}>Sell Max</button>
            </td>
          </tr>
          <tr>
            <td>Select Marketplace</td>
            <td>
              <select onChange={(e) => handleMarketplaceChange(e)}>
                {invItem.item.sellCosts.map(
                  (marketplace: Marketplace, index: number) => (
                    <option key={index} value={index}>
                      {marketplace.getLocation()}
                    </option>
                  )
                )}
              </select>
            </td>
          </tr>
          <tr>
            <td>Price Per Unit</td>
            <td>${price}</td>
          </tr>
          <tr>
            <td>Fee Percentage:</td>
            <td>{feePercentage}%</td>
          </tr>
          <tr>
            <td>Total Fees</td>
            <td>${fees}</td>
          </tr>
          <tr>
            <td>Transportation Costs:</td>
            <td>${transporationCosts}</td>
          </tr>
          <tr>
            <td>Total:</td>
            <td>${total}</td>
          </tr>
        </tbody>
      </table>
      {sellError ? (
        <div id="sellError" className="errorQuantity">
          {errorMsg}
        </div>
      ) : null}
      <button id="sellButton" onClick={() => submitSell()}>
        Sell
      </button>
    </div>
  );
}
