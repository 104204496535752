import { MemoryRouter as Router, Route, Routes } from 'react-router-dom';
import Global from './Global';

export default function App() {
  return (
    <Router>
      <Routes>
        <Route path="" element={<Global />} />
      </Routes>
    </Router>
  );
}
