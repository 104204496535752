import inventory from '../../../../Global/Attributes/Inventory/inventory';
import inventoryItem from '../../../../Global/Attributes/Inventory/inventoryItem';
import { allItems } from '../../../../Global/Attributes/Item/AllItems';

export default function loadInventory(
  _cookieInventory: any[],
  savedDataInventory: inventory
) {
  for (let i = 0; i < _cookieInventory.length; i++) {
    // if not valid then skip item error
    let { invItem_ID, invItem_amount } = _cookieInventory[i];

    if (typeof invItem_ID != 'number' || typeof invItem_amount != 'number') {
      // if not valid then skip item and error
      console.log('Invalid inventory item: ', _cookieInventory[i]);
    } else {
      savedDataInventory.addInventoryItem(
        new inventoryItem(allItems[invItem_ID], invItem_amount)
      );
    }
  }
}
