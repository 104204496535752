import { useEffect, useState } from 'react';
import './style.css';
import SellPanel from '../SellPanel';
import iReducer from '../../../Global/State/reducerInterface';
import inventoryItem from '../../../Global/Attributes/Inventory/inventoryItem';

interface iInventoryItem extends iReducer {
  inventoryItem: inventoryItem;
  currInventoryItems: inventoryItem[];
}

export default function InventoryItem({
  state,
  dispatch,
  inventoryItem,
  currInventoryItems,
}: iInventoryItem) {
  const [showSellPanel, setShowSellPanel] = useState(false);

  const toggleSellPanel = () => {
    setShowSellPanel((showSellPanel) => !showSellPanel);
  };

  // When inventoryItems hook is updated in Inventory component
  // we want to force all sell panels to be closed
  useEffect(() => {
    setShowSellPanel(false);
  }, [currInventoryItems]);

  return inventoryItem.amount ? (
    <div className="inventoryContent">
      <div className="inventoryItem">
        <div>{inventoryItem.item.displayName}</div>
        <div className="inventoryOptions">
          <div className="itemOption">Amount: {inventoryItem.amount}</div>
          <button
            id="toggleSell"
            className="itemOption"
            onClick={() => toggleSellPanel()}
          >
            Show Sell
          </button>
        </div>
      </div>
      {showSellPanel ? (
        <SellPanel state={state} dispatch={dispatch} invItem={inventoryItem} />
      ) : null}
    </div>
  ) : null;
}
