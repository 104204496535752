import Probe from '../Probes/Probe';

export default class Warehouse {
  private isStorage: boolean;
  private capacity: number;
  private nickname: string;
  public probes: Probe[];

  constructor(
    _isStorage: boolean,
    _capacity: number,
    _nickname: string,
    _probes: Probe[]
  ) {
    this.isStorage = _isStorage;
    this.nickname = _nickname;
    this.capacity = _capacity;
    this.probes = _probes;
  }

  getNickName() {
    return this.nickname;
  }

  setNickName = (newNickname: string) => {
    this.nickname = newNickname;
  };

  getIsStorage() {
    return this.isStorage;
  }

  getCapacity() {
    return this.capacity;
  }
}
