import './style.css';
// import { motion } from 'framer-motion';
import { Menu, Award, FileText, Settings, HelpCircle } from 'react-feather';

interface iNav {
  toggleNav: CallableFunction;
  toggleManagement: CallableFunction;
  toggleSettings: CallableFunction;
  togglePlayerDetails: CallableFunction;
}

export default function Nav({
  toggleNav,
  toggleManagement,
  toggleSettings,
  togglePlayerDetails,
}: iNav) {
  return (
    <div className="navBar">
      <div className="navLeft">
        {/* Unused currently */}
        <Menu className="navIcon" size={26} onClick={() => toggleNav()} />
      </div>
      <div
        id="navManagement"
        className="navManagement"
        onClick={() => toggleManagement()}
      >
        Management
      </div>
      <div className="navRight">
        <HelpCircle id="navHelpCirle" className="navIcon helpIcon" size={26} />
        <Award
          id="navAward"
          className="navIcon achievementsIcon"
          size={26}
          onClick={() => togglePlayerDetails()}
        />
        <FileText className="navIcon changeLogIcon" size={26} />
        <Settings
          id="navSettings"
          className="navIcon settingsIcon"
          size={26}
          onClick={() => toggleSettings()}
        />
      </div>
    </div>
  );
}
