import Transaction from './Transaction/Transaction';

export function addTransaction(
  transactionHistory: Transaction[],
  newTransaction: number | string | boolean | Transaction
) {
  if (newTransaction instanceof Transaction) {
    transactionHistory.unshift(newTransaction);

    if (transactionHistory.length > 150) transactionHistory.pop();
  }

  return transactionHistory;
}
