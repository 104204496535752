export default function loadCompendium(_cookieCompendiumData: number[]) {
  let results = new Map<number, boolean>();

  for (let i = 0; i < _cookieCompendiumData.length; i++) {
    let compendiumItemID = _cookieCompendiumData[i];
    if (typeof compendiumItemID === 'number')
      results.set(compendiumItemID, true);
  }

  return results;
}
