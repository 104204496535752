import Item from './Item';
import Marketplace from './Marketplace/Marketplace';

// -----
// *** These values should never be changed by player ***
// -----
export const allItems: Record<number, Item> = {
  0: new Item(0, 'MissingNo.', []),
  1: new Item(1, 'Moon Rocks (Djudd)', [
    new Marketplace(0, 'Wolfal Harbor (Culper)', 0.27, 111, 111, 35),
    new Marketplace(1, 'Browl City (Culper)', 0.27, 122, 222, 55),
    new Marketplace(2, 'Titian', 0.8, 333, 333, 113),
    new Marketplace(3, 'Djudd', 1, 444, 444, 235),
    new Marketplace(4, 'Sigil Station (Asteroid Belt)', 2.7, 555, 555, 1235),
    new Marketplace(5, 'Lesh', 3, 666, 666, 1435),
  ]),
  2: new Item(2, 'Moon Rocks (Titian)', [
    new Marketplace(0, 'Wolfal Harbor (Culper)', 0.27, 111, 111, 35),
    new Marketplace(1, 'Browl City (Culper)', 0.27, 122, 222, 55),
    new Marketplace(2, 'Titian', 0.8, 333, 333, 113),
    new Marketplace(3, 'Djudd', 1, 444, 444, 235),
    new Marketplace(4, 'Sigil Station (Asteroid Belt)', 2.7, 555, 555, 1235),
    new Marketplace(5, 'Lesh', 3, 666, 666, 1435),
  ]),
  3: new Item(3, 'Ocean Water (Lesh)', [
    new Marketplace(0, 'Wolfal Harbor (Culper)', 0.27, 121, 121, 35),
    new Marketplace(1, 'Browl City (Culper)', 0.27, 232, 232, 55),
    new Marketplace(2, 'Titian', 0.8, 343, 343, 113),
    new Marketplace(3, 'Djudd', 1, 454, 454, 235),
    new Marketplace(4, 'Sigil Station (Asteroid Belt)', 2.7, 55, 55, 1235),
    new Marketplace(5, 'Lesh', 3, 1, 1, 1435),
  ]),
};
