import State from "./State";
import Action from "./Action";

import { updateMoney } from "../Attributes/Money/MoneyOperations";
import {
  incrementAmount,
  decrementAmount,
} from "../Attributes/Item/ItemOperations";
import { updateTotalStatValue } from "../Attributes/Statistics/StatOperations";
import { addTransaction } from "../Attributes/History/HistoryOperations";
// import { calculateInventory } from "../GameLogic/gameLogic";
import { updateCompendium } from "../Attributes/Compendium/CompendiumOperations";

export function reducer(state: State, action: Action) {
  switch (action.attribute) {
    case "money": {
      if (action.operation === "update") {
        return {
          ...state,
          money: updateMoney(state.money, action.payload),
        };
      }

      return state;
    }
    case "item": {
      if (action.operation === "incrementAmount") {
        return {
          ...state,
          inventory: incrementAmount(
            state.inventory,
            action.id,
            action.payload
          ),
        };
      }
      if (action.operation === "decrementAmount") {
        return {
          ...state,
          inventory: decrementAmount(
            state.inventory,
            action.id,
            action.payload
          ),
        };
      }

      return state;
    }
    case "stat": {
      if (action.operation === "updateTotalMoney") {
        return {
          ...state,
          totalMoneyEarned: updateTotalStatValue(
            state.totalMoneyEarned,
            action.payload
          ),
        };
      }
      if (action.operation === "updateTotalItemsSold") {
        return {
          ...state,
          totalItemsSold: updateTotalStatValue(
            state.totalItemsSold,
            action.payload
          ),
        };
      }
      if (action.operation === "updateTotalTransportCostsPaid") {
        return {
          ...state,
          totalTransportationCostsPaid: updateTotalStatValue(
            state.totalTransportationCostsPaid,
            action.payload
          ),
        };
      }

      return state;
    }
    case "history": {
      if (action.operation === "addTransaction") {
        return {
          ...state,
          tranactionHistory: addTransaction(
            state.transactionHistory,
            action.payload
          ),
        };
      }

      return state;
    }
    case "probeCycle": {
      if (action.operation === "triggerProbeCycle") {
        // New items being added
        // const { inventoryChanges, ftrProbes, warehouseChanges } =
        //   calculateInventory(state.warehouses);

        return {
          ...state,
          // inventory:
          // storage:
        };
      }

      return state;
    }
    case "compendium": {
      if (action.operation === "updateCompendium") {
        return {
          ...state,
          compendium: updateCompendium(state.compendium, action.payload),
        };
      }

      return state;
    }
    default:
      return state;
  }
}
