export default class Upgrades {
  private size: string;
  private storage: string;
  private shielding: string;
  private exteriorColor: string;

  constructor(
    _size: 'S' | 'M' | 'L' | 'XL',
    _storage:
      | 'Liquid'
      | 'Metal'
      | 'Soil'
      | 'Animal (Aquatic)'
      | 'Animal (Terrestrial)',
    _shielding: 'None' | 'Thin' | 'Thick' | 'Reinforced' | 'Heavily Reinforced',
    _exteriorColor: 'Grey' | 'Blue' | 'Black' | 'Jungle Camo'
  ) {
    this.size = _size;
    this.storage = _storage;
    this.shielding = _shielding;
    this.exteriorColor = _exteriorColor;
  }

  getSize() {
    return this.size;
  }

  getStorage() {
    return this.storage;
  }

  getShielding() {
    return this.shielding;
  }

  getExteriorColor() {
    return this.exteriorColor;
  }
}
