import Warehouse from '../../../../Global/Attributes/Facilities/Warehouse/Warehouse';
import inventory from '../../../../Global/Attributes/Inventory/inventory';
import Cookies from 'universal-cookie';
import { currentVersion } from '../currentVersion';

// Used for testing purposes only
// ONLY RESETS COOKIE NOT STATE
export default function resetCookie() {
  const cookies = new Cookies();
  cookies.set('phantom', {
    version: currentVersion,
    money: 0,
    pets: [],
    inventory: new inventory([]),
    compendium: new Map<number, boolean>(),
    transactionHistory: [],
    totalMoneyEarned: 0,
    totalItemsSold: 0,
    totalTransportationCostsPaid: 0,
    storage: new Warehouse(true, 0, 'Storage', []),
    warehouses: [],
  });
}
