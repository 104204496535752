import Upgrades from '../Upgrades/Upgrades';
import Item from '../../Item/Item';

export default class Probe {
  private amount: number;
  private targetItem: Item;
  private targetLocation: string;
  private upgrades: Upgrades;

  constructor(
    _amount: number,
    _targetItem: Item,
    _targetLocation: string,
    _upgrades: Upgrades
  ) {
    this.amount = _amount;
    this.targetItem = _targetItem;
    this.targetLocation = _targetLocation;
    this.upgrades = _upgrades;
  }

  getAmount() {
    return this.amount;
  }

  getUpgrades() {
    return this.upgrades;
  }

  getTargetItem() {
    return this.targetItem;
  }

  getTargetLocation() {
    return this.targetLocation;
  }

  setUpgrades = (newUpgrades: Upgrades) => {
    this.upgrades = newUpgrades;
  };
}
