import './style.css';
import { motion } from 'framer-motion';
import { range } from 'lodash';
import iReducer from '../../../Global/State/reducerInterface';
import InventoryItem from '../InventoryItem';
import { useState, useEffect } from 'react';
import {
  ChevronLeft,
  ChevronsLeft,
  ChevronRight,
  ChevronsRight,
} from 'react-feather';

export default function Inventory({ state, dispatch }: iReducer) {
  let pageSize = 2;
  let [page, setPage] = useState(1);

  const [inventoryItems, setInventoryItems] = useState(
    state.inventory.getInventoryItems()
  );
  const [searchQuery, setSearchQuery] = useState('');
  const [showMostRecent, setShowMostRecent] = useState(true);

  const currentQuery = (query: string) => {
    setSearchQuery(query);
  };

  const updateMostRecent = (checked: boolean) => {
    setShowMostRecent(checked);
  };

  useEffect(() => {
    let _inv = state.inventory.getInventoryItems();

    // Remove items with amount 0
    _inv = _inv.filter((_invItem) => _invItem.amount > 0);

    // Alphabetical or Most Recent Order
    if (!showMostRecent)
      _inv = _inv.sort((a, b) =>
        a.item.displayName.localeCompare(b.item.displayName)
      );

    // Search Query
    if (searchQuery) {
      setPage(1);
      _inv = _inv.filter((_invItem) =>
        _invItem.item.displayName
          .toLowerCase()
          .includes(searchQuery.toLowerCase())
      );
    }

    // Pagination Slice
    const lower = pageSize * (page - 1);
    const upper = lower + pageSize;

    _inv = _inv.slice(lower, upper);

    if (_inv.length) {
      setInventoryItems(_inv);
    } else {
      page === 0 ? setPage(1) : setPage((page -= 1));
    }
  }, [page, showMostRecent, searchQuery, state]);

  return (
    <motion.div
      className="inventoryTable"
      initial={{ opacity: 0 }}
      animate={{
        opacity: 1,
      }}
      transition={{
        delay: 0.1,
        duration: 0.5,
      }}
    >
      <div className="inventoryHeader">Current Inventory</div>

      <div className="inventoryCustomization">
        <div className="inventorySearch">
          <div>Search</div>
          <input className="" onChange={(e) => currentQuery(e.target.value)} />
          <div>Most Recent</div>
          <input
            type="checkbox"
            checked={showMostRecent}
            onChange={(e) => updateMostRecent(e.target.checked)}
          />
        </div>
      </div>
      {range(0, Object.keys(inventoryItems).length).map((i) => (
        <InventoryItem
          key={i}
          state={state}
          dispatch={dispatch}
          inventoryItem={inventoryItems[i]}
          currInventoryItems={inventoryItems}
        />
      ))}

      <div className="paginationContainer">
        <div>Pagination</div>
        <div className="arrows">
          <ChevronsLeft
            className="paginationArrow"
            size={26}
            onClick={() => setPage(1)}
          />
          <ChevronLeft
            className="paginationArrow"
            size={26}
            onClick={() => (page === 1 ? setPage(1) : setPage((page -= 1)))}
          />
          <div>{page}</div>
          <ChevronRight
            className="paginationArrow"
            size={26}
            onClick={() => setPage((page += 1))}
          />
          <ChevronsRight
            className="paginationArrow"
            size={26}
            onClick={() => null}
          />
        </div>
      </div>
    </motion.div>
  );
}
