import Probe from '../../../../Global/Attributes/Facilities/Probes/Probe';
import Upgrades from '../../../../Global/Attributes/Facilities/Upgrades/Upgrades';
import { allItems } from '../../../../Global/Attributes/Item/AllItems';

// Must be any since it's being passed from cookie
export default function convertProbesForWarehouse(possibleWarehouse: any) {
  let probes: Probe[] = [];

  for (let i = 0; i < possibleWarehouse.length; i++) {
    const { P_amount, P_targetItem, P_targetLocation, P_upgrades } =
      possibleWarehouse[i];

    const { U_size, U_storage, U_shielding, U_color } = P_upgrades;

    if (
      isNaN(P_amount) ||
      typeof P_targetItem != 'number' ||
      allItems[P_targetItem] === undefined ||
      typeof P_targetLocation != 'string' ||
      (typeof U_size != 'string' &&
        !(U_size === 'S' || U_size === 'M' || U_size === 'L')) ||
      (typeof U_storage != 'string' &&
        !(
          U_storage === 'Liquid' ||
          U_storage === 'Metal' ||
          U_storage === 'Soil' ||
          U_storage === 'Animal (Aquatic)' ||
          U_storage === 'Animal (Terrestrial)'
        )) ||
      (typeof U_shielding != 'string' &&
        !(
          U_shielding === 'None' ||
          U_shielding === 'Thin' ||
          U_shielding === 'Thick' ||
          U_shielding === 'Reinforced' ||
          U_shielding === 'Heavily Reinforced'
        )) ||
      (typeof U_color != 'string' &&
        !(
          U_color === 'Grey' ||
          U_color === 'Blue' ||
          U_color === 'Black' ||
          U_color === 'Jungle Camo'
        ))
    ) {
      console.log('Invalid probe in warehouse: ', possibleWarehouse[i]);
    } else {
      probes.push(
        new Probe(
          P_amount,
          allItems[P_targetItem],
          P_targetLocation,
          new Upgrades(U_size, U_storage, U_shielding, U_color)
        )
      );
    }
  }

  return probes;
}
