import { useReducer } from 'react';
import Cookies from 'universal-cookie';
import Home from '../pages/Home/Home';
import { reducer } from './State/Reducer';
import loadGlobalData from './State/InitData/loadGlobalData';

export default function Global() {
  const cookies = new Cookies();

  console.log('PRE: ', cookies.get('phantom'));
  cookies.set(
    'phantom',
    {
      money: 145,
      inventory: [
        {
          invItem_ID: 3,
          invItem_amount: 14,
        },
        {
          invItem_ID: 1,
          invItem_amount: 1,
        },
        {
          invItem_ID: 2,
          invItem_amount: 30,
        },
      ],
      compendium: [1, 3],
      transactionHistory: [
        {
          TH_itemName: 'Moon Rocks (Djudd)',
          TH_isBuy: false,
          TH_buyPrice: 0,
          TH_sellPrice: 111,
          TH_transportationCost: 135,
          TH_quantity: 88,
          TH_feesPaid: 1000,
          TH_marketplaceName: 'Wolfal Harbor (Culper)',
          TH_total: 46,
        },
      ],
      totalMoneyEarned: 44,
      totalItemsSold: 55,
      totalTransportationCostsPaid: 135,
      storage: [
        {
          P_amount: 5,
          P_targetItem: 1,
          P_targetLocation: 'Titan',
          P_upgrades: {
            U_size: 'S',
            U_storage: 'Soil',
            U_shielding: 'None',
            U_color: 'Grey',
          },
        },
        {
          P_amount: 15,
          P_targetItem: 2,
          P_targetLocation: 'location2',
          P_upgrades: {
            U_size: 'M',
            U_storage: 'Liquid',
            U_shielding: 'Thin',
            U_color: 'Blue',
          },
        },
        {
          P_amount: 25,
          P_targetItem: 3,
          P_targetLocation: 'location3',
          P_upgrades: {
            U_size: 'M',
            U_storage: 'Liquid',
            U_shielding: 'Thick',
            U_color: 'Black',
          },
        },
      ],
      warehouses: [
        {
          W_isStorage: false,
          W_capacity: 100,
          W_nickName: 'Happy One',
          W_probes: [
            {
              P_amount: 11,
              P_targetItem: 2,
              P_targetLocation: 'Titan',
              P_upgrades: {
                U_size: 'S',
                U_storage: 'Soil',
                U_shielding: 'None',
                U_color: 'Grey',
              },
            },
            {
              P_amount: 11,
              P_targetItem: 1,
              P_targetLocation: 'location2',
              P_upgrades: {
                U_size: 'M',
                U_storage: 'Liquid',
                U_shielding: 'Thin',
                U_color: 'Blue',
              },
            },
            {
              P_amount: 11,
              P_targetItem: 3,
              P_targetLocation: 'location3',
              P_upgrades: {
                U_size: 'M',
                U_storage: 'Liquid',
                U_shielding: 'Thick',
                U_color: 'Black',
              },
            },
          ],
        },
        {
          W_isStorage: false,
          W_capacity: 100,
          W_nickName: 'Number Two',
          W_probes: [
            {
              P_amount: 2,
              P_targetItem: 2,
              P_targetLocation: 'Titian',
              P_upgrades: {
                U_size: 'S',
                U_storage: 'Soil',
                U_shielding: 'None',
                U_color: 'Grey',
              },
            },
            {
              P_amount: 22,
              P_targetItem: 3,
              P_targetLocation: 'location2',
              P_upgrades: {
                U_size: 'M',
                U_storage: 'Liquid',
                U_shielding: 'Thin',
                U_color: 'Blue',
              },
            },
            {
              P_amount: 22,
              P_targetItem: 2,
              P_targetLocation: 'location3',
              P_upgrades: {
                U_size: 'M',
                U_storage: 'Liquid',
                U_shielding: 'Thick',
                U_color: 'Black',
              },
            },
          ],
        },

        {
          W_isStorage: false,
          W_capacity: 100,
          W_nickName: 'Triple Three',
          W_probes: [
            {
              P_amount: 11,
              P_targetItem: 2,
              P_targetLocation: 'Titan',
              P_upgrades: {
                U_size: 'S',
                U_storage: 'Soil',
                U_shielding: 'None',
                U_color: 'Grey',
              },
            },
            {
              P_amount: 11,
              P_targetItem: 1,
              P_targetLocation: 'location2',
              P_upgrades: {
                U_size: 'M',
                U_storage: 'Liquid',
                U_shielding: 'Thin',
                U_color: 'Blue',
              },
            },
            {
              P_amount: 11,
              P_targetItem: 3,
              P_targetLocation: 'location3',
              P_upgrades: {
                U_size: 'M',
                U_storage: 'Liquid',
                U_shielding: 'Thick',
                U_color: 'Black',
              },
            },
          ],
        },
      ],
    },
    { path: '/', sameSite: 'none', secure: true }
  );
  console.log('LOADED COOKIE', cookies.get('phantom'));

  const [state, dispatch] = useReducer(reducer, loadGlobalData());

  return <Home state={state} dispatch={dispatch} />;
}
