import inventoryItem from './inventoryItem';

export default class inventory {
  private inventoryItems: inventoryItem[];

  constructor(_inventoryItems: inventoryItem[]) {
    this.inventoryItems = _inventoryItems;
  }

  getInventoryItems() {
    return this.inventoryItems;
  }

  getInventoryItemByID = (_id: number) => {
    for (let i = 0; i < this.inventoryItems.length; i++) {
      if (this.inventoryItems[i].item.id === _id) {
        return this.inventoryItems[i];
      }
    }

    return null;
  };

  // If no duplicate push new item
  addInventoryItem = (_newItem: inventoryItem) => {
    if (!this.mergeDuplicateItems(_newItem)) {
      this.inventoryItems.push(_newItem);
    }
  };

  // Merges redundant items into single entry with concat'd amounts
  private mergeDuplicateItems(_newItem: inventoryItem) {
    for (let i = 0; i < this.inventoryItems.length; i++) {
      if (this.inventoryItems[i].item.id === _newItem.item.id) {
        this.inventoryItems[i].amount += _newItem.amount;
        return true;
      }
    }

    return false;
  }
}
