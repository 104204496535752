export default class Transaction {
  // Update to item once global item hashmap is setup
  private item: string;

  private isBuy: boolean;
  private buyPrice: number;
  private sellPrice: number;
  private transportationCost: number;
  private quantity: number | string;
  private feesPaid: number;
  private marketplaceName: string;
  private total: number;

  constructor(
    _item: string,
    _isBuy: boolean,
    _buyPrice: number,
    _sellPrice: number,
    _transportationCost: number,
    _quantity: number | string,
    _feesPaid: number,
    _marketplaceName: string,
    _total: number
  ) {
    this.item = _item;
    this.isBuy = _isBuy;
    this.buyPrice = _buyPrice;
    this.sellPrice = _sellPrice;
    this.transportationCost = _transportationCost;
    this.quantity = _quantity;
    this.feesPaid = _feesPaid;
    this.marketplaceName = _marketplaceName;
    this.total = _total;
  }

  getItem() {
    return this.item;
  }

  getIsBuy() {
    return this.isBuy;
  }

  getBuyPrice() {
    return this.buyPrice;
  }

  getSellPrice() {
    return this.sellPrice;
  }

  getTransportationCosts() {
    return this.transportationCost;
  }

  getQuantity() {
    return this.quantity;
  }

  getFeesPaid() {
    return this.feesPaid;
  }

  getMarketplaceName() {
    return this.marketplaceName;
  }

  getTotal() {
    return this.total;
  }
}
