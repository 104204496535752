import { range } from 'lodash';
import iReducer from '../../../../Global/State/reducerInterface';

export default function TransactionHistory({ state, dispatch }: iReducer) {
  return (
    <div>
      <h3>Transaction History</h3>
      <b>Most Recent:</b>
      <hr />
      <table>
        {range(0, state.transactionHistory.length).map((i) => (
          <>
            <tr>
              <td>Item: </td>
              <td>{state.transactionHistory[i].getItem()}</td>
            </tr>
            <tr>
              <td>Marketplace:</td>
              <td>{state.transactionHistory[i].getMarketplaceName()}</td>
            </tr>
            <tr>
              <td>Quantity:</td>
              <td>{state.transactionHistory[i].getQuantity()}</td>
            </tr>
            <tr>
              <td>Total:</td>
              <td>
                ${state.transactionHistory[i].getTotal().toLocaleString()}
              </td>
            </tr>
          </>
        ))}
      </table>
    </div>
  );
}
